import styled from "styled-components";

export const SPointsContent = styled.div``;

export const SPointsTable = styled.table`
  font-size: 11px;
  border: none;
  border-spacing: 0;
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 5px;
  @media only screen and (min-width: 768px) {
    font-size: 15px;
    margin-left: 10px;
    margin-top: 10px;
  }
  td {
    padding: 3px 6px 3px 3px;
  }
`;

export const SPointsCellSmall = styled.td`
  font-size: 9px;
  @media only screen and (min-width: 768px) {
    font-size: 11px;
  }
`;

export const SPointsCellBold = styled.td`
  font-weight: bold;
`;
export const SPointsCellFun = styled.td`
  font-weight: bold;
  font-family: "Marker Felt";
`;

export const SButtonWrapper = styled.div`
  margin-left: 15px;
  margin-top: 15px;
`;

export const SNewFbosContent = styled.table`
  font-size: 10px;
  border: none;
  border-spacing: 0;
  margin-left: 5px;
  margin-top: 15px;
  @media only screen and (min-width: 768px) {
    font-size: 13px;
    margin-left: 10px;
    margin-top: 30px;
  }
  td {
    padding: 3px 4px 3px 4px;
  }
`;

export const SPointsRow = styled.tr`
  &:hover {
    background: #eeeeee;
    td {
      background: #eeeeee;
    }
  }
`;

export const SNewFbosCellSmall = styled.td`
  font-size: 10px;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
`;

export const SNewFbosCellSmallCenter = styled.td`
  font-size: 10px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
`;

export const SCellWithLeftBorder = styled.td`
  border-left: 1px dotted grey;
`;

export const SCellNumber = styled.td`
  border-left: 1px dotted grey;
  text-align: right;
`;
