import React, {useEffect, useReducer} from "react";
import {AppBar, Box, Button, createTheme, ThemeProvider, Toolbar} from "@mui/material";
import {TImportedCountryDetail, TImportedCountryFboReports} from "../../types";
import {
    fetchFboDetails,
    fetchNewFbos,
    fetchReports,
    initialImportedReports,
} from "../../helpers/useImportReports";
import {
    SCellNumber,
    SCellWithLeftBorder,
    SNewFbosCellSmall,
    SNewFbosCellSmallCenter,
    SNewFbosContent,
    SPointsCellBold,
    SPointsCellFun,
    SPointsCellSmall,
    SPointsContent,
    SPointsRow,
    SPointsTable,
} from "../../components/points-table/points-table.styles";
import {getDateString} from "../../helpers/getDateString";
import pointsViewReducer, {
    EPointsViewActions,
    initialState,
    TPointsFboReport,
} from "./points.reducer";
import Logo from "../../components/logo/logo";

const motivation = [
    'JESTEM SPEŁNIONA',
    'JESTEM ZAJEBISTA',
    'JESTEM SZCZĘŚLIWA',
    'DOCENIAM SIEBIE',
    'JESTEM MILIONEREM',
    'WIEM PO CO TO ROBIĘ'
];

const mainFbo = '480900095021';

const mapDetails = (
    actual: Record<string, TImportedCountryDetail>,
    prev?: Record<string, TImportedCountryDetail>
) => {
    const details = [];
    for (let iKey in actual) {
        let actualVal = actual[iKey];
        let prevVal = prev ? prev[iKey] ?? null : null;
        details.push({
            country: iKey,
            totalCC: actualVal.totalCC,
            prevTotalCC: prevVal?.totalCC ?? 0,
        });
    }
    return details;
};

// const mapCountryReports = (
//     actual: Record<string, TImportedCountryFboReports>,
//     prev?: Record<string, TImportedCountryFboReports>
// ) => {
//     const details = [] as TPointsFboReport[];
//     for (let country in actual) {
//         if (actual && actual[country]) {
//             mapFboReports(actual[country], prev ? prev[country] ?? null : null)
//         }
//     }
// }

const mapFboReports = (
    actual: Record<string, TImportedCountryFboReports>,
    prev?: Record<string, TImportedCountryFboReports>
) => {
    let details = [] as TPointsFboReport[];
    for (let country in actual) {
        if (actual && actual[country]) {
            let actualArray = actual[country];
            let prevArray = prev ? prev[country] ?? null : null;

            const det = mapGeneration(
                country,
                mainFbo,
                actualArray,
                prevArray
            ) as TPointsFboReport[];

            details = [...details, ...det];
        }
    }

    return details;
};

const mapGeneration = (
    country: string,
    sponsorId: string,
    actualArray: TImportedCountryFboReports,
    prevArray: TImportedCountryFboReports | null
) => {
    let details = [] as TPointsFboReport[];
    for (let iKey in actualArray) {
        let actualFboReport = actualArray[iKey];
        let prevFboReport = prevArray ? prevArray[iKey] ?? null : null;

        if (actualFboReport.sponsorId === sponsorId) {
            // const moreGen = (actualFboReport.generation && actualFboReport.generation > 1 );
            details.push({
                fboId: actualFboReport.fboId,
                country: (actualFboReport.generation === 1 ) ? country : '',
                actualName: (actualFboReport.generation ? addSpaces(actualFboReport.generation) : '') + actualFboReport.name,
                actualCC: actualFboReport.totalCC,
                prevCC: ((prevFboReport?.totalCC ?? 0) * 1000) / 1000,
                prevMonth: ((actualFboReport.lastMonthCC ?? 0) * 1000) / 1000,
                activity: actualFboReport.activityCC > 4 ? 'TAK' : '-',
                position: actualFboReport.position,
            });

            //find next generation
            const det = mapGeneration(country, actualFboReport.fboId, actualArray, prevArray);
            details = [...details, ...det];
        }
    }
    return details;
}

const addSpaces = (counter: number) => {
    let spaces = '';
    for (let i = 1; i < counter; i++) {
        spaces = spaces + ' - ';
    }
    return spaces;
}

const Points = () => {
    const [state, dispatch] = useReducer(pointsViewReducer, initialState);
    const {reports, refreshDate, dates, newFbos, fboReports} = state;
    const [motivationText, setMotivationText] = React.useState(motivation[Math.floor(Math.random() * motivation.length)]);

    const [importedReports, setImportedReports] = React.useState(
        initialImportedReports
    );

    const refetchReports = () => {
        fetchReports()
            .then((data) => {
                setImportedReports(data);
            })
            .catch((e) => console.log(e));
    };

    const refetchNewFbos = () => {
        fetchNewFbos()
            .then((data) => {
                dispatch({
                    type: EPointsViewActions.changeNewFbos,
                    newFbos: data,
                });
            })
            .catch((e) => console.log(e));
    };

    const refetchFboDetails = () => {
        if (dates.actual && dates.prev) {
            fetchFboDetails(dates.actual, dates.prev).then((fboReports) => {
                if (fboReports) {
                    dispatch({
                        type: EPointsViewActions.changeFboReports,
                        fboReports: mapFboReports(fboReports.actual, fboReports.prev),
                    });
                }
            });
        }
    };

    const refetchAll = () => {
        refetchReports();
        refetchNewFbos();

        dispatch({
            type: EPointsViewActions.changeRefreshDate,
            date: getDateString(),
        });

        setMotivationText(motivation[Math.floor(Math.random() * motivation.length)]);
    };

    useEffect(() => {
        refetchAll();
    }, []);

    useEffect(() => {
        dispatch({
            type: EPointsViewActions.changeReports,
            reports: {
                global: {
                    dateActual: importedReports?.latest?.date ?? "-",
                    datePrev: importedReports?.prev?.date ?? "-",
                    dateModified: importedReports?.latest?.dateModified ?? "-",
                    actual: importedReports?.latest?.totalCC ?? 0,
                    prev: importedReports?.prev?.totalCC ?? 0,
                    activity: importedReports?.latest?.activityCC ?? 0,
                },
                details: mapDetails(
                    importedReports?.latest?.countries,
                    importedReports?.prev?.countries
                ),
            },
        });

        dispatch({
            type: EPointsViewActions.changeDates,
            dates: {
                actual: importedReports?.latest?.date ?? null,
                prev: importedReports?.prev?.date ?? null,
            },
        });
    }, [importedReports]);

    useEffect(() => {
        refetchFboDetails();
    }, [dates]);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{display: "flex"}}>
                <AppBar position="fixed">
                  <Toolbar sx={{minHeight: 50, gap: '10px'}}>
                    <Logo type="diamonds"/>
                    My Points
                    <Logo type="forever"/>
                  </Toolbar>
                </AppBar>
                <Box sx={{width: "100%"}}>
                    <Toolbar/>
                    <SPointsContent>
                        <SPointsTable>
                            <tbody>
                            <tr>
                                <SPointsCellSmall>Odświeżone</SPointsCellSmall>
                                <SPointsCellSmall colSpan={2}>
                                    {state.refreshDate}
                                </SPointsCellSmall>
                                <SPointsCellSmall rowSpan={2}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={refetchAll}
                                    >
                                        odśwież
                                    </Button>
                                </SPointsCellSmall>
                            </tr>
                            <tr>
                                <SPointsCellSmall>Stan</SPointsCellSmall>
                                <SPointsCellSmall colSpan={3}>
                                    {reports.global.dateModified}
                                </SPointsCellSmall>
                            </tr>
                            <tr>
                                <SPointsCellSmall colSpan={4}>&nbsp;</SPointsCellSmall>
                            </tr>
                            <tr>
                                <SPointsCellBold>Globalne CC:</SPointsCellBold>
                                <SPointsCellBold>{reports.global.actual}</SPointsCellBold>
                                <td>
                                    (+{(reports.global.actual - reports.global.prev).toFixed(3)})
                                </td>
                                <SPointsCellFun rowSpan={2}>
                                    !!! {motivationText} !!!
                                </SPointsCellFun>
                            </tr>
                            <tr>
                                <td>Aktywność:</td>
                                <td colSpan={3}>{reports.global.activity}</td>
                            </tr>
                            <tr>
                                <SPointsCellSmall>Data:</SPointsCellSmall>
                                <SPointsCellSmall
                                    colSpan={3}>{reports.global.dateActual} / {reports.global.datePrev}</SPointsCellSmall>
                            </tr>
                            <tr>
                                <SPointsCellSmall colSpan={4}>&nbsp;</SPointsCellSmall>
                            </tr>
                            <tr>
                                <td colSpan={4}>Szczegóły:</td>
                            </tr>
                            {reports.details.map((report) => (
                                <tr key={report.country}>
                                    <SPointsCellBold>{report.country}:</SPointsCellBold>
                                    <td>{report.totalCC.toFixed(3)}</td>
                                    <td colSpan={2}>
                                        (+
                                        {(report.totalCC - (report.prevTotalCC ?? 0)).toFixed(3)})
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </SPointsTable>
                    </SPointsContent>
                    {newFbos && (
                        <SNewFbosContent>
                            <tbody>
                            <tr>
                                <SNewFbosCellSmall colSpan={4}>Nowi:</SNewFbosCellSmall>
                            </tr>
                            {newFbos.map((fbo) => (
                                <SPointsRow key={"newFbo-" + fbo.fboId}>
                                    <td>{fbo.name}</td>
                                    <SCellWithLeftBorder>{fbo.fboId}</SCellWithLeftBorder>
                                    <SCellWithLeftBorder>{fbo.level}</SCellWithLeftBorder>
                                    <SCellWithLeftBorder>{fbo.date}</SCellWithLeftBorder>
                                </SPointsRow>
                            ))}
                            </tbody>
                        </SNewFbosContent>
                    )}
                    {fboReports && (
                        <SNewFbosContent>
                            <tbody>
                            <tr>
                                <SNewFbosCellSmall colSpan={6}>Szczegółowo
                                    ({dates.actual} / {dates.prev}) </SNewFbosCellSmall>
                            </tr>
                            <tr>
                                <SNewFbosCellSmall>Kraj</SNewFbosCellSmall>
                                <SNewFbosCellSmall>FBO</SNewFbosCellSmall>
                                <SNewFbosCellSmallCenter>Akt</SNewFbosCellSmallCenter>
                                <SNewFbosCellSmallCenter>Dod</SNewFbosCellSmallCenter>
                                <SNewFbosCellSmallCenter>Poprz</SNewFbosCellSmallCenter>
                                <SNewFbosCellSmallCenter>2mce</SNewFbosCellSmallCenter>
                                <SNewFbosCellSmallCenter>4CC</SNewFbosCellSmallCenter>
                            </tr>
                            {fboReports.map((fboReport) => (
                                <SPointsRow
                                    key={"fboR-" + fboReport.fboId }
                                >
                                    <td>{fboReport.country}</td>
                                    <td>{fboReport.actualName}
                                        {fboReport.position === "Preferred Customer" && (
                                            <i> (PC)</i>
                                        )}
                                    </td>
                                    <SCellNumber>{fboReport.actualCC.toFixed(3)}</SCellNumber>
                                    <SCellNumber>
                                        (+
                                        {(fboReport.actualCC - fboReport.prevCC).toFixed(3)})
                                    </SCellNumber>
                                    <SCellNumber>{fboReport.prevMonth.toFixed(3)}</SCellNumber>
                                    <SCellNumber>
                                        {(fboReport.actualCC + fboReport.prevMonth).toFixed(3)}
                                    </SCellNumber>
                                    <td>{fboReport.activity}</td>
                                </SPointsRow>
                            ))}
                            </tbody>
                        </SNewFbosContent>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Points;
