import { TNewFbos, TReports } from "../types";

// const url = "http://home.krzystanek.pl:10299/recentReport";
// const url = "http://localhost:8000/api/global";

// const urlReports = "http://localhost:8000/api/global";
// const urlNewFbos = "http://localhost:8000/api/fbo/new";
// const urlReport = "http://localhost:8000/api/reports/{date}";

const urlReports = "https://api.be4ever.pl/api/global";
const urlNewFbos = "https://api.be4ever.pl/api/fbo/new";
const urlReport = "https://api.be4ever.pl/api/reports/{date}";

export const initialImportedReports: TReports = {
  fbo: "",
  latest: {},
  prev: {},
};

export const initialImportedFbos: TNewFbos = {
  fbos: [],
};

export const fetchReports = async () => {
  try {
    const response = await fetch(urlReports);
    return await response.json();
  } catch (e) {
    console.log(e);
  }
};

export const fetchNewFbos = async () => {
  try {
    const response = await fetch(urlNewFbos);
    return await response.json();
  } catch (e) {
    console.log(e);
  }
};

export const fetchFboDetails = async (date: string, datePrev: string) => {
  try {
    const responseActual = await fetch(urlReport.replace("{date}", date));
    const resultsActual = await responseActual.json();

    const responsePrev = await fetch(urlReport.replace("{date}", datePrev));
    const resultsPrev = await responsePrev.json();

    return {
      actual: resultsActual,
      prev: resultsPrev,
    };
  } catch (e) {
    console.log(e);
  }
};
