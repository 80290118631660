export const lengthMin2 = (str: string) => {
  return str.length < 2 ? "0" + str : str;
};

export const getDateString = (date?: string) => {
  let d = new Date();
  if (date) {
    d = new Date(date);
  }
  const year = d.getFullYear().toString();
  let mon = lengthMin2((d.getMonth() + 1).toString());
  let day = lengthMin2(d.getDate().toString());
  let hrs = lengthMin2(d.getHours().toString());
  let min = lengthMin2(d.getMinutes().toString());
  let sec = lengthMin2(d.getSeconds().toString());

  return [year, mon, day].join("-") + " " + [hrs, min, sec].join(":");
};
