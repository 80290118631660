import { TCountryDetail } from "../../types";

export type TPointsDates = {
  actual: null | string;
  prev: null | string;
};

export type TPointsReports = {
  global: {
    dateActual: string;
    datePrev: string;
    dateModified: string;
    actual: number;
    prev: number;
    activity: number;
  };
  details: TCountryDetail[];
};

export type TPointsNewFbos = {
  fboId: null | string;
  name: null | string;
  level: null | string;
  date: null | string;
};

export type TPointsFboReport = {
  fboId: string;
  country: string;
  actualName: string;
  actualCC: number;
  prevCC: number;
  prevMonth: number;
  activity: string;
  position: string|null;
};

export interface IPointsViewState {
  refreshDate: null | string;
  dates: TPointsDates;
  reports: TPointsReports;
  newFbos: null | TPointsNewFbos[];
  fboReports: null | TPointsFboReport[];
}

export const initialState: IPointsViewState = {
  refreshDate: null,
  dates: {
    actual: null,
    prev: null,
  },
  reports: {
    global: {
      dateActual: "-",
      datePrev: "-",
      dateModified: "-",
      actual: 0,
      prev: 0,
      activity: 0,
    },
    details: [] as TCountryDetail[],
  },
  newFbos: null,
  fboReports: null,
};

export enum EPointsViewActions {
  changeRefreshDate,
  changeDates,
  changeReports,
  changeNewFbos,
  changeFboReports,
}

export type TPointsViewActions =
  | {
      type: EPointsViewActions.changeRefreshDate;
      date: string;
    }
  | {
      type: EPointsViewActions.changeDates;
      dates: TPointsDates;
    }
  | {
      type: EPointsViewActions.changeReports;
      reports: TPointsReports;
    }
  | {
      type: EPointsViewActions.changeNewFbos;
      newFbos: TPointsNewFbos[] | null;
    }
  | {
      type: EPointsViewActions.changeFboReports;
      fboReports: TPointsFboReport[] | null;
    };

const pointsViewReducer = (
  state: IPointsViewState,
  action: TPointsViewActions
): IPointsViewState => {
  switch (action.type) {
    case EPointsViewActions.changeRefreshDate: {
      const { date } = action;
      return {
        ...state,
        refreshDate: date,
      };
    }
    case EPointsViewActions.changeDates: {
      const { dates } = action;
      return {
        ...state,
        dates,
      };
    }
    case EPointsViewActions.changeReports: {
      const { reports } = action;
      return {
        ...state,
        reports,
      };
    }
    case EPointsViewActions.changeNewFbos: {
      const { newFbos } = action;
      return {
        ...state,
        newFbos,
      };
    }
    case EPointsViewActions.changeFboReports: {
      const { fboReports } = action;
      return {
        ...state,
        fboReports,
      };
    }
    default:
      return { ...state };
  }
};

export default pointsViewReducer;
